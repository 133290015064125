<template>
  <div v-if="isLoading">
    <div>
      <h1 class="mr-sm-4 header-tablepage">LINE SETTING</h1>
    </div>
    <b-tabs>
      <b-tab no-body title="Line Setting">
        <div class="p-3 bg-white">
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Member Liff URL"
              placeholder="Member Liff URL"
              type="text"
              name="member_liff_url"
              class="w-100"
              v-model="form.memberLiffUrl"
            />
            <font-awesome-icon
              class="icon-copy"
              icon="copy"
              id="tooltip-memberLiffUrl"
              @click="copyWord('memberLiffUrl')"
              v-b-tooltip.hover
              title="Copy URL"
            />
            <b-tooltip
              target="tooltip-memberLiffUrl"
              ref="tooltip-memberLiffUrl"
              triggers="click"
              delay.hide="500"
            >
              <b>Copy !</b>
            </b-tooltip>
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Campaign Liff URL"
              placeholder="Campaign Liff URL"
              type="text"
              name="campaign_liff_url"
              class="w-100"
              v-model="form.campaignLiffUrl"
            />

            <font-awesome-icon
              class="icon-copy"
              icon="copy"
              @click="copyWord('campaignLiffUrl')"
              id="tooltip-campaignLiffUrl"
              v-b-tooltip.hover
              title="Copy URL"
            />
            <b-tooltip
              target="tooltip-campaignLiffUrl"
              ref="tooltip-campaignLiffUrl"
              triggers="click"
              delay.hide="500"
            >
              <b>Copy !</b>
            </b-tooltip>
          </div>

          <b-form-checkbox
            id="display-transaction"
            v-model="form.customer_display_transaction_detail"
            name="display-transaction"
            value="1"
            unchecked-value="0"
          >
            Display Transaction Detail
          </b-form-checkbox>
          <b-form-checkbox
            id="display-point-channel"
            v-model="form.line_display_point_channel"
            name="display-point-channel"
            value="1"
            unchecked-value="0"
          >
            Display Point And Connect Channel
          </b-form-checkbox>
          <b-form-checkbox
            id="display-member-level"
            v-model="form.line_display_member_level"
            name="display-member-level"
            value="1"
            unchecked-value="0"
          >
            Display Member Level
          </b-form-checkbox>
          <b-form-checkbox
            id="display-outstanding"
            v-model="form.line_display_service_outstanding"
            name="display-outstanding"
            value="1"
            unchecked-value="0"
          >
            Display Service Outstanding
          </b-form-checkbox>
          <b-form-checkbox
            id="display-logout-button"
            v-model="form.line_display_logout_button"
            name="display-logout-button"
            value="1"
            unchecked-value="0"
          >
            Display Logout Button
          </b-form-checkbox>
          <b-form-checkbox
            id="display-code-countdown"
            v-model="form.line_display_code_countdown"
            name="display-code-countdown"
            value="1"
            unchecked-value="0"
          >
            Display Code Countdown
          </b-form-checkbox>

          <b-form-checkbox
            id="display-right-request-link"
            v-model="form.line_display_right_request_link"
            name="display-right-request-link"
            value="1"
            unchecked-value="0"
          >
            Display Right Request Link
          </b-form-checkbox>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputSelect
              title="QR Code"
              class="w-100 my-3"
              name="qr_type"
              :options="qrOpt"
              v-model="form.qr_code"
            />
          </div>
          <hr />
          <h5 class="mt-3"><b>Label</b></h5>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0 mt-3"
          >
            <InputText
              textFloat="Login Title"
              placeholder="Login Title"
              type="text"
              name="login-title"
              class="w-100"
              v-model="form.login_title"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Register Titile"
              placeholder="Register Titile"
              type="text"
              name="register-title"
              class="w-100"
              v-model="form.register_title"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Telephone Titile"
              placeholder="Telephone Titile"
              type="text"
              name="telephone-title"
              class="w-100"
              v-model="form.telephone_title"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Telephone Sub Titile"
              placeholder="Telephone Sub Titile"
              type="text"
              name="telephone-title"
              class="w-100"
              v-model="form.telephone_subtitle"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Redeem Exceed Quota per Customer Title"
              placeholder="Redeem Exceed Quota per Customer Title"
              type="text"
              name="redeem-exceed-quota-per-customer"
              class="w-100"
              v-model="form.redeem_exceed_quota_per_customer_title"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Redeem Exceed Quota Title"
              placeholder="Redeem Exceed Quota Title"
              type="text"
              name="redeem-exceed-quota-title"
              class="w-100"
              v-model="form.redeem_exceed_quota_title"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Redeem Button"
              placeholder="Redeem Button"
              type="text"
              name="redeem-button"
              class="w-100"
              v-model="form.redeem_button"
            />
          </div>
          <hr />
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <UploadFileV2
              textFloat="Font"
              placeholder="Please Upload Font"
              class="w-100"
              format="font"
              name="Font"
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="form.custom_font_name"
              v-model="form.custom_font"
              id="uploadfile"
              :isValidType="false"
              text=""
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputSelect
              title="Font Size"
              class="w-100"
              name="qr_type"
              :options="fontSizeOpt"
              v-model="form.font_size"
            />
          </div></div
      ></b-tab>
      <b-tab no-body title="Display">
        <div class="p-3 bg-white">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Layout" v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="form.line_layout"
                  :aria-describedby="ariaDescribedby"
                  name="line_layout"
                  value="Standard"
                  >Standard</b-form-radio
                >
                <b-form-radio
                  v-model="form.line_layout"
                  :aria-describedby="ariaDescribedby"
                  name="line_layout"
                  value="Tier"
                  >Tier</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col>
              <div class="wrap-panel" style="--textPreview: 'List Preview'">
                <div
                  class="layout p-2"
                  v-bind:style="{ backgroundImage: 'url(' + bgImage + ')' }"
                >
                  <template>
                    <div class="my-auto w-100 text-center"></div>
                  </template>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>

    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/ModalLoading";
export default {
  components: { InputText, ModalLoading },
  data() {
    return {
      isLoading: true,
      qrOpt: ["Telephone", "Member ID."],
      fontSizeOpt: ["Normal", "Large"],
      form: {
        memberLiffUrl: "",
        campaignLiffUrl: "",
        qr_code: "Telephone",
        customer_display_transaction_detail: "0",
        line_display_point_channel: "0",
        line_display_member_level: "0",
        line_display_service_outstanding: "0",
        line_display_code_countdown: "0",
        line_display_logout_button: "0",
        line_display_right_request_link: "0",
        register_title: "",
        login_title: "",
        telephone_title: "",
        telephone_subtitle: "",
        redeem_button: "",
        redeem_exceed_quota_per_customer_title: "",
        redeem_exceed_quota_title: "",
        custom_font: "",
        custom_font_name: "",
        font_size: "Normal",
      },
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    onFileChange(file) {
      this.form.custom_font_name = file.name;
      this.isDisable = false;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.custom_font = reader.result;
      };
    },
    deleteFile() {},
    async getList() {
      this.isLoading = false;
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/setting/getline`
      );
      this.form = response.data.detail;
      this.form.custom_font_name = response.data.detail.custom_font_name;
      this.isLoading = true;
    },

    async saveForm() {
      this.$refs.modalLoading.show();
      this.$Axios
        .post(`${process.env.VUE_APP_API}/setting/updateline`, this.form)
        .then((res) => {
          if (res.data.result) {
            this.successAlert().then(
              (val) => val && this.$router.push("/setting")
            );
          } else {
            this.errorAlert(res.data.message);
          }
          this.$refs.modalLoading.hide();
        });
    },
    copyWord(key) {
      if (!this.form[key]) {
        return;
      }
      this.$copyText(this.form[key]);
      // this.$clipboard(this.form[key]);
      this.$refs["tooltip-" + key].$emit("open");
      setTimeout(() => {
        this.$refs["tooltip-" + key].$emit("close");
      }, 500);
    },
  },
};
</script>
<style scoped>
label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
.icon-copy {
  margin-top: 0.4rem;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 250px;
}
.layout {
  width: 100%;
  border-radius: 0.75rem;
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 19.5;

  background: gray;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
}
</style>
